import { LocationStation, LocationFacility } from 'state/ducks/locations/models'

// hooks
import { replaceLocale } from 'hooks/common/lang'

// style
import styles from './AreaPlaceList.module.scss'

interface Props {
  prefectureSlug: string
  places: LocationStation[] | LocationFacility[]
  label: string
  countryCode: string
  locale: string
  headingStyle?: string
}

type PlaceAreaListProps = {
  places: LocationStation[] | LocationFacility[]
  countryCode: string
  prefectureSlug: string
  locale: string
}

const PlaceAreaList = ({ places, countryCode, prefectureSlug, locale }: PlaceAreaListProps) => {
  const getName = (locale, info) => {
    return locale === 'ja' ? info.name : info[`${locale}_name`]
  }

  // 言語の修正
  const replacedLocale: string = replaceLocale(locale)

  return (
    <ul className={styles.placeAreaList}>
      {places && (
        <>
          {places.map((place: LocationStation | LocationFacility, index: number) => (
            <li className={styles.placeAreaListItem} key={index}>
              <a href={`/${replacedLocale}/${countryCode}/city/${prefectureSlug}/${place.id}`}>
                {getName(locale, place)}
              </a>
            </li>
          ))}
        </>
      )}
    </ul>
  )
}

const AreaPlaceList: React.VFC<Props> = ({
  prefectureSlug,
  places,
  label,
  countryCode,
  locale,
  headingStyle,
}: Props) => {
  // 見出しのスタイル一覧
  const headingStyles = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

  return (
    <>
      {headingStyle && headingStyles.includes(headingStyle) ? (
        <div className={styles.placeArea}>
          {headingStyle === 'h1' && <h1 className={styles.placeAreaTitle}>{label}</h1>}
          {headingStyle === 'h2' && <h2 className={styles.placeAreaTitle}>{label}</h2>}
          {headingStyle === 'h3' && <h3 className={styles.placeAreaTitle}>{label}</h3>}
          {headingStyle === 'h4' && <h4 className={styles.placeAreaTitle}>{label}</h4>}
          {headingStyle === 'h5' && <h5 className={styles.placeAreaTitle}>{label}</h5>}
          {headingStyle === 'h6' && <h6 className={styles.placeAreaTitle}>{label}</h6>}
          <div className={styles.placeAreaListWrap}>
            <PlaceAreaList
              places={places}
              countryCode={countryCode}
              prefectureSlug={prefectureSlug}
              locale={locale}
            />
          </div>
        </div>
      ) : (
        <dl className={styles.placeArea}>
          <dt className={styles.placeAreaTitle}>{label}</dt>
          <dd className={styles.placeAreaListWrap}>
            <PlaceAreaList
              places={places}
              countryCode={countryCode}
              prefectureSlug={prefectureSlug}
              locale={locale}
            />
          </dd>
        </dl>
      )}
    </>
  )
}

export default AreaPlaceList
