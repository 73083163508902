import { PRICE } from 'configs/space'
import { SpaceCurrency } from 'state/ducks/space/models'

/**
 * 国コード、サイズの種類を判定して、金額を取得する
 * @param {string} country_code 国コード
 * @param {string} size_type サイズの種類
 * @returns
 */
export const useSpacePrice = (country_code: string = 'jpn', size_type: string): string => {
  // 国コードの有効判定チェック
  const countryCodes: string[] = ['jpn', 'twn']

  country_code = countryCodes.includes(country_code) ? country_code : 'jpn'

  const price: number = PRICE[size_type][country_code]

  let currency: string = ''

  switch (country_code) {
    case 'jpn':
      currency = SpaceCurrency['cny']
      break
    case 'twn':
      currency = SpaceCurrency['twd']
      break
    case 'en':
      currency = SpaceCurrency['cny']
      break
    default:
      currency = SpaceCurrency['cny']
      break
  }

  return `${currency}${price.toLocaleString()}`
}
