import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import checkBreakPoint from 'lib/checkBreakpoint'

import styles from './OwnerSection.module.scss'
import { useRouter } from 'next/router'

const OwnerSection = () => {
  const { t } = useTranslation()
  const { isDesktop } = checkBreakPoint()
  const { locale } = useRouter()

  return (
    <section className={styles.ownerSection}>
      <div className={styles.ownerSectionBox}>
        {isDesktop && (
          <img src="/owner_section_illustration.webp" alt={t('META.OWNER_SECTION_ILLUSTRATION')} />
        )}
        <div className={styles.ownerSectionBoxTitle}>{t('TOP.OWNER.TITLE')}</div>
        <button className={styles.ownerSectionBoxButton}>
          <Link
            href={`https://join-cloak.ecbo.io/owner${locale === 'zh-TW' ? `/${locale}` : ''}`}
            passHref
          >
            <a target="_blank" rel="noreferrer">
              {t('TOP.OWNER.REGISTER')}
            </a>
          </Link>
        </button>
      </div>
    </section>
  )
}

export default OwnerSection
