import { useIntercom } from 'react-use-intercom'
import dayjs from 'dayjs'

import { IntercomAccount } from 'types/intercom'

export function useCustomIntercom() {
  const { boot, showNewMessage } = useIntercom()

  const openIntercomMessage = (
    isShowNewMessage?: boolean | false,
    account?: IntercomAccount | null
  ) => {
    if (account) {
      boot({
        email: account.email,
        name: account.name,
        phone: account.phone,
        userId: account.userId,
        createdAt: String(dayjs().unix()),
      })
    } else {
      boot({
        createdAt: String(dayjs().unix()),
      })
    }

    if (isShowNewMessage) {
      showNewMessage()
    }
  }
  return { openIntercomMessage }
}
