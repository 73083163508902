export const PRICE_SIZE_TYPE_BAG: string = 'bag'
export const PRICE_SIZE_TYPE_SUITCASE: string = 'suitcase'

// 金額
export const PRICE = {
  [PRICE_SIZE_TYPE_BAG]: {
    jpn: 500,
    en: 500,
    twn: 80,
  },
  [PRICE_SIZE_TYPE_SUITCASE]: {
    jpn: 800,
    en: 800,
    twn: 160,
  },
}
